.careersection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.careerrow1{
    width: 80%;
    display: flex;
}

.careercolumn1, .careercolumn2{
    width: 50%;
    text-align: center;
    cursor: pointer;
}

.careercolumn1, .careercolumn2:hover{
    background-color: #333;
    color: white;
}

.careercolumn1{
    background-color: var(--e-global-color-fd86af4);
    color: white;
    padding: 10px 0;
}


.careercolumn2{
    background-color: #eee;
    padding: 10px 0;
}

.careercolumn3{
    background-color: var(--e-global-color-fd86af4);
    color: white;
    padding: 10px 0;
}

.careercolumnpara{
    margin-bottom: 0;
}

.careerrow2{
    width: 80%;
}

.careerrow2innerrow{
    margin: 30px 0;
    border: 2px solid #ccc;
    padding: 20px;
}

.careerrow2innerrow1{
    display: flex;
    width: 100%;
}

.careerinnercolumn1,.careerinnercolumn3{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.careerinnercolumn2{
    width: 60%;
    padding: 10px 30px;
}

.careerheading{
    font-weight: 600;
}
.careerimg{
    width: 100%;
    height: 170px;
}

.careerbtn{
    font-family: "Roboto", Sans-serif;
    font-weight: 500;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: var(--e-global-color-7492ce2);
    border-radius: 50px 50px 50px 50px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
}

.careerrow2innerrow2{
    border: 2px solid #eee;
    padding: 8px 20px 8px 20px;
    margin: 20px 0 10px 0;
    cursor: pointer;
}

.accordion-title{
    margin-bottom: 0;
}

.accordianspan{
    margin-right: 10px;
}

.carerdescription .accordion-content {
    display: none;
}

.carerdescription.active .accordion-content {
    display: block;
}

.description-content {
    padding: 10px;
    color: black;
}

.descriptionul{
    list-style: circle;
    color: black;
}

.arrow {
    transition: transform 0.3s ease;
    display: inline-block;
}

.rotate {
    transform: rotate(180deg);
}

.elsepartpara{
    margin: 30px 0;
}

.model{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 999;
}


.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition:opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    background-color: rgba(0, 0, 0, .5);;

}


.modelcloseButton{
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 5px;
    font-size: 35px;
    background: transparent;
    color: white;
    cursor: pointer;
    z-index: 999;
    border: none;
}

@media screen and (max-width:768px) {
    .careerrow1,.careerrow2{
        width: 95%;
    }
}

@media screen and (max-width:600px) {
    .careerrow1{
        width: 95%;
    }

    .careerrow2innerrow1{
        flex-direction: column;
    }

    .careerinnercolumn1,.careerinnercolumn2,.careerinnercolumn3{
        width: 100%;
    }

    .careerinnercolumn3{
        justify-content: flex-start;
    }

    .careerinnercolumn2{
        padding: 15px 0;
    }

    .modelcloseButton{
        position: absolute;
        right: 10px;
        top: 10px;
        color: white;
    }

    .model.open{
        height: 100vh;
    }
}
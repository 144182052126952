.contactussection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.contactuscolumnheading{
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
}

.contactusrow{
    width: 85%;
    display: flex;
    margin-top: 30px;
}

.contactuscolumn1{
    width: 70%;
    padding: 20px;
    background-color: #eee;
}

.contactuscolumn2{
    width: 30%;
    padding: 30px;
    background-color: var(--e-global-color-7492ce2);
    color: white;
}

.contactuscolumninnerdiv{
    row-gap: 20px;
    display: flex;
    margin-top: 15px;
    width: 100%;
}

.contactuscolumninnerdiv1{
    margin-top: 15px;
    width: 100%;
    text-align: center;
}

.contactusinput{
width: 100%;
padding: 15px;
border: 1px solid #ccc;
}

.contactusinputbtn{
    padding: 10px 20px;
    width: 100%;
    border: none;
    text-align: center;
    background-color: var(--e-global-color-7492ce2);
    color: white;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.contactcolumn2heading{
    font-weight: 700;
    font-size: 25px;
    color: white;
}

.contactcolumnheading{
    margin: 30px 0;
}

.contactaddressdiv{
    width: 95%;
    background-color: white;
    border-radius: 10px;
    color: black;
    padding: 20px;
}

.addressheading{
    font-family: "Nunito", Sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.addresspara{
    color: #989696;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.contactdetailsdiv{
    display: flex;
    /* width: 95%; */
    justify-content: space-around;
    margin: 20px 0;
}

.contactdetailsheading{
    width: 50%;
    height: 30px;
}
.contactdetailspara{
    color: #FFFFFF;
    font-family: "Nunito", Sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 16px;
}

.socialiconsul{
    display: flex;
    justify-content: space-around;
}

.socialiconsli{
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px;
    font-size: 25px;
    background-color: #0B9EDA40;
    cursor: pointer;
}

.socialiconsli:hover{
    background-color: #0a1131;
}


@media screen and (max-width:768px) {
    .contactusrow{
        flex-direction: column;
        width: 95%;
    }

    .contactuscolumn1,.contactuscolumn2{
        width: 100%;
        padding: 30px;
    }

}

@media screen and (max-width:600px) {
    .contactuscolumn1,.contactuscolumn2{
        padding: 8px;
    }
}


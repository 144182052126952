.whowearediv{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
}
  
.c-left > p{
    color: var(--e-global-color-fd86af4);
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
}


.whoarewebtn{
    font-family: "Nunito", Sans-serif;
    font-weight: 700;
    background-color: transparent;
    background-image: linear-gradient(180deg, #0B9EDA 0%, #1064B7 100%);
    border-radius: 31px 31px 31px 31px;
    padding: 16px 31px 16px 31px;
    color: white;
    border: none;
}
.notfounddiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

}

.notfounddiv > h1{
    text-align: center;
    color: white;
    background-color: gray;
    padding: 20px;
    width: 100%;
}
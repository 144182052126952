.recentprojectsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  row-gap: 30px;
}

.recentprojectheadingdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.rpheading {
  margin: 0px 0px 0px 12px;
  padding: 0px 6px 0px 19px;
  border-style: solid;
  border-width: 0px 4px 0px 4px;
  border-color: var(--e-global-color-fe4ec32);
  border-radius: 7px 7px 7px 7px
}

.recentprojectrow2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recentprojectcardrow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  gap: 30px;
  margin: 30px 0;
}

.project-card {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* flex: 1 0 20%; */
  cursor: pointer;
  max-width: 22%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  color: black;
  text-decoration: none;
}

.project-card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.project-details {
  text-align: center;
}

.project-title {
  margin-bottom: 10px;
}

.highlight-points {
  list-style: none;
  padding: 0;
}

.highlight-points li {
  margin-bottom: 5px;
}

@media screen and (max-width:768px) {
  .recentprojectcardrow {
    width: 95%;
  }

  .project-card {
    max-width: 30%;
  }

}

@media screen and (max-width:600px) {
  .recentprojectcardrow {
    flex-direction: column;
  }

  .project-card {
    max-width: 100%;
  }
}
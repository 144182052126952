.popupdiv {
    position: absolute;
    bottom: 85%;
    right: 9%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    height: 80px;
    width: 200px;
    z-index: 3;
  }
  
  
  .closeButton {
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #333;
    margin-bottom: 5px;
    position: absolute;
    right: 8px;
    top: 0;
  }

  .loginpopupdiv{
    display: flex;
    flex-direction: column;
    color: black;
  }

  .loginpopupdiv > Link{
    color: black !important;
    text-decoration: none !important;
  }
  
  .closeButton:hover {
    color: #ff0000; 
  }
  
  @media screen and (max-width:600px) {
    .popupdiv{
        bottom: 82%;
        right: 9%;
    }
  }
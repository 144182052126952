.certificatediv{
    width: 100vw;
    height: 100vh;
    color: white;
    background-color: var(--blue);
    background-image: url('data:image/svg+xml;utf8,<svg width="55" height="45" viewBox="0 0 55 45" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M5.49121 44.2515C5.49121 40.4116 7.02223 36.7289 9.74745 34.0137C12.4727 31.2985 16.1689 29.7731 20.0229 29.7731C23.877 29.7731 27.5732 31.2985 30.2984 34.0137C33.0236 36.7289 34.5546 40.4116 34.5546 44.2515M20.0229 20.724C20.0229 16.8841 21.5539 13.2014 24.2791 10.4862C27.0044 7.77095 30.7006 6.24554 34.5546 6.24554C38.4087 6.24554 42.1049 7.77095 44.8301 10.4862C47.5553 13.2014 49.0863 16.8841 49.0863 20.724V44.2515" stroke="%230261CC50" stroke-width="11"/></svg>');
    background-size: 160%;
    background-position: 90% 150%;
    background-repeat: no-repeat;
    padding: 2.5rem;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

svg {
    position: relative;
    top: 0;
  }

.content {
    position: absolute;
    top: 17rem;
    right: 15.5rem;
    width: 50%;
    text-align: center;
  }
  
  .content * {
    margin-bottom: 1rem;
  }
  
  .content h1 {
    font-family: 'Poppins', sans-serif;
    color: var(--white);
    /* color:black; */
    font-size: 3rem !important;
    line-height: 1;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  
  .content h2 {
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 1;
  }
  
  .content > p{
    text-align: start;
    line-height: 2.5;
  }
  .byline {
    position: absolute;
    right: 15.5rem;
    bottom: -3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .date {
    position: absolute;
    bottom: 2.5rem;
    font-size: 0.75rem;
  }
  
  .bold {
    font-weight: 500;
  }
.container-4 {
  background: #f8f8f8;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-4 .c-top {
  text-align: center;
  padding: 13px
}

.container-4 h6 {
  font-weight: 600;
  color: deepskyblue;
}

.card-container {
  display: flex;
  gap: 75px;
  width: 80%;
  border-radius: 10px;
}

.leftcardcontainer {
  width: 50%;
}

.card-container .left-card {
  display: flex;
  gap: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 8px 0 #ccc;
  border-radius: 10px;
  animation: slideUp 3s linear;
}

.left-card-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-card-2 {
  padding: 10px;
}

@keyframes slideUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}


@media screen and (max-width:768px) {
  .card-container {
    gap: 15px;
    width: 95%;
  }
  .card-container .left-card {
   height: 160px;
  }
}

@media screen and (max-width:600px) {
  .card-container {
    flex-direction: column;
    width: 90%;
    gap: 10px;
  }

  .c-left,
  .c-right {
    width: 100%;
  }

  .leftcardcontainer {
    width: 100%;
  }
}
.hiringprocesssection {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.hiringprocessdiv{
    max-width: 100%;
    background-color: #eee;
    border-radius: 10px;
    padding: 30px;
}

.hiringprocessdiv > h3{
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
}

.hiringprocessdiv > p > span{
    font-weight: 600;
    color: black;
}

@media screen and (max-width:600px) {
    .hiringprocessdiv{
        width: 90%;
    }
}
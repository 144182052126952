*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
--e-global-color-primary: #6EC1E4;
--e-global-color-secondary: #54595F;
--e-global-color-text: #7A7A7A;
--e-global-color-accent: #61CE70;
--e-global-color-fd86af4: #002244;
--e-global-color-fe4ec32: #0B9EDA;
--e-global-color-7492ce2: #1064B7;
--e-global-color-9ee7120: #FFA01D;
--e-global-color-224c1be: #F8F8F861;
--e-global-typography-primary-font-family: "Roboto";
--e-global-typography-primary-font-weight: 600;
--e-global-typography-secondary-font-family: "Roboto Slab";
--e-global-typography-secondary-font-weight: 400;
--e-global-typography-text-font-family: "Roboto";
--e-global-typography-text-font-weight: 400;
--e-global-typography-accent-font-family: "Roboto";
--e-global-typography-accent-font-weight: 500;
}

a{
  text-decoration: none;
  color: black;
}

a:hover{
  text-decoration: none;
}

.lay {
  padding: 0;
}

.navbar{
  box-shadow: 0px 0px 10px 0px rgba(160.93125915527344, 160.93125915527344, 160.93125915527344, 0.5);
  z-index: 2;
  padding: 0 50px;
  display: flex;
  align-items: self-end;
}

.header {
  height: 47px;
  background: #082244;
  display: flex;
  color: #fff;
  /* justify-content: flex-end; */
}

.typho-1 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  width: auto;
  font-size: 15px;
}

.logo {
  width: 150px;
  padding-bottom: 5px;
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* home.js css start */
.containerr {
  /* height: 500px; */
  background: #0a1131;
  display: flex;
  padding: 50px 0;
}

.containerr .c-lite-1 Button {
  font-weight: 700;
  background-color: #2D7E9F;
  border-radius: 0px 0px 0px 0px;
  margin-top: 60px;
}

.homediv{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0a1131;
}


.homecolumn1, .homecolumn2{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}
.c-lite-1 {
  color: #fff;
}


.container-2 {
  margin: 30px 0px;
  padding: 42px 0;
  display: flex;
  width: 85%;
  gap: 30px;
}

.c-left{
  width: 55%;
}

.c-right{
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-2 h3 {
  color: rgb(11, 158, 218);
  font-weight: 700;
  font-size: 18px;
}

.c-left p {
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 25px;
}

.c-left h2 {
  color: #171717;
  font-family: "Nunito", Sans-serif;
  font-weight: 700;
  margin-top: 25px;
}

.c-left .btn {
  border-radius: 39px;
}

.c-right-img{
  width: 100%;
  /* height: 400px; */
}

/* commerce css start */
.commerce{
  height: 122px;
  border: 1px solid red;
  margin-top: 30px;
  
}
.g-b{
  height: 100px;
  border: 1px solid black;

}
.grid-c{
  gap: 143px;
}
/* commerce css end */

@media screen and (max-width:768px) {
  .container-2 {
    width: 95%;
    flex-direction: column;
  }
  
  .c-left{
    width: 100%;
  }
  
  .c-right{
    width: 100%;
  }
  
}


@media screen and (max-width:600px) {
  .navbar{
    padding: 0 10px;
  }
  .logo{
    width: 120px;
  }
  .searchform{
    display: none;
  }
  .containerr{
    flex-direction: column;
    width: 100%;
  }

  .box{
    flex-direction: column;
    width: 90%;
  }

  .box-front{
    padding: 0;
    width: 100%;
  }

  .container-2{
    flex-direction: column;
    width: 90%;
  }
  
}
.on {
    margin-top: 48px !important;
    font-size: 31px !important;
    text-align: center;
    font-weight: 600 !important;
    margin-bottom: 31px !important;
}

.servicediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
}

.box {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
}

.box-content {
    width: 31%;
    height: 350px;
    margin: 10px;
    perspective: 1000px;
    cursor: pointer;
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.box-content.active_hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    backface-visibility: hidden;
}

.card-front {
    background: #0988bb;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-back {
    background: #1064b7;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg);
}

.card-front img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.card-front h4 {
    margin: 10px 0;
    text-align: center;
}

.card-front p,
.card-back p {
    margin: 0;
    text-align: center;
}

@media screen and (max-width:768px) {
    .servicediv{
        width: 100%;
    }
    
    .box-content {
        width: 45%;
    }
    
}

@media screen and (max-width:600px) {
    .box-content {
        width: 100%;
        height: 320px;
        margin: 10px 0;
    }
}
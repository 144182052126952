.container-3 {
    text-align: center;
    padding: 30px;
  }
  
  
  .container-3 h5 {
    font-size: 1.25rem;
    font-weight: 600;
    color: deepskyblue;
  }
  
  .img-section{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .workprocessimg{
    width: 70%;
    height: 45vh;
  }

  @media screen and (max-width:600px) {
    .workprocessimg{
        width: 90%;
        height: 30vh;
    }
  }

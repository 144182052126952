.careermegamenu{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 220%;
    padding: 5px 15px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-top: 1px solid black;
    cursor: pointer;
    border-radius: 10px;
    transition: visibility 0.5s, opacity 0.5s;
}

@media screen and (max-width:600px) {
    .careermegamenu{
        width: 90%;
        position: relative;
        display: none;
        overflow-y: scroll;
        visibility: visible;
        opacity: 1;
        top: 0;
        left: 0;
    }

    .careermegamenu.open {
        display: block;
    }

    .careermegamenu:hover{
        visibility: initial;
        opacity: 1;
      }

}
.servediv {
  /* background: #f8f8f8; */
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.servediv .serve-top {
  text-align: center;
  padding: 13px
}

.servediv h6 {
  font-weight: 600;
  color: deepskyblue;
}

.servecard-container {
  display: flex;
  gap: 40px;
  width: 80%;
  border-radius: 10px;
}

.serveleftcardcontainer {
  width: 50%;
}

.servecard-container .serveleft-card {
  display: flex;
  gap: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px;
  cursor: pointer;
  animation: slideUp 3s linear;
  border-style: dotted;
  border-width: 2px 2px 2px 2px;
  border-color: #EBEBEB;
  transition: hover 0.5s linear;
}

.servecard-container .serveleft-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}



.serveimage {
  width: 100%;
  height: 50px;
}

.serveleft-card-1,
.serveleft-card-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.serveleft-card-2 {
  padding: 10px;
}


@keyframes slideUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (max-width:768px) {

  .servecard-container {
    gap: 15px;
    width: 95%;
  }

  .servecard-container .serveleft-card {
    padding: 10px;
    height: 80px;
  }

  .serveimage {
    height: 40px;
  }

  .serveleft-card-2 h5{
    font-size: 15px;
  }
}

@media screen and (max-width:600px) {
  .servecard-container {
    flex-direction: column;
    width: 90%;
    gap: 10px;
  }

  .c-left,
  .c-right {
    width: 100%;
  }

  .serveleftcardcontainer {
    width: 100%;
  }
}
.opdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}

.oprow{
    display: flex;
    width: 80%;
    gap: 20px;
    border-radius: 10px;
    background-color: #F4F4F4;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    padding: 30px;
    margin: 20px 0;
}

.opcolumn1, .opcolumn2{
    width: 50%;
} 

.opcolumnimg{
    width: 100%;
    height: 280px;
    border-radius: 7px;
    vertical-align: middle;
    display: inline-block;
}

.opcolumnheading{
    color: #171717;
    font-family: "Nunito", Sans-serif;
    font-weight: 700;
}

.opcolumnpara{
    color: #002244;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 30px;
}

.opcolumnbtn{
    font-family: "Nunito", Sans-serif;
    font-weight: 700;
    background-color: transparent;
    background-image: linear-gradient(180deg, #0B9EDA 0%, #1064B7 100%);
    border-radius: 31px 31px 31px 31px;
    padding: 16px 31px 16px 31px;
    border: none;
    color: white;
    margin-top: 20px;
}



@media screen and (max-width:768px) {
    .oprow{
        flex-direction: column;
        width: 90%;
        padding: 20px;
        gap: 40px;
    }

    .opcolumn1,.opcolumn2{
        width: 100%;
    }
}
.header{
    padding: 0 20px;
}

.typho-1 > a{
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
}

.navbardiv{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.navbardiv1{
    top: 50px;
    z-index: -1;
    position: absolute;
    left: 0;
    padding-left: 20px;
    width: 100%;
    transition: all 0.5s ease-in;
    background-color: white;
}

.navbar-nav{
 padding: 0 30px;
}

.navbarul > li{
    margin: 0 50px;
}

.nav-item{
    padding: 20px 10px;
    cursor: pointer;
}


.servicenavitem:hover .service-mega-menu{
  visibility: visible !important;
  opacity: 1 !important;
}


.productnavitem,.careernavitem{
  position: relative;
}

.careernavitem:hover .careermegamenu,
.productnavitem:hover .productmegamenu{
    visibility: visible;
    opacity: 1;
}

.hamburger{
    font-size: 2rem;
    cursor: pointer;
    color: gray;
    position: absolute;
    display: none;
    right: 8px;
    top: 8px;
}

.newheaderheading{
    font-weight: 500;
    color: white;
    margin-bottom: 0;
}

.headersecond{
    width: 35%;
    display: flex;
    gap: 30px;
    justify-content: center;
    padding: 0 10px;
}

@keyframes marqueeAnimation {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .marquee-content {
    display: inline-block;
    animation: marqueeAnimation 15s linear infinite;
  }
  

@media screen and (max-width:768px) {
    .header{
        padding:12px 5px;
        /* gap: 15px; */
        flex-direction: column-reverse;
    }
    .navbardiv{
        justify-content: flex-start;
        position: absolute;
        top: -1000px;
        left: -100px;
        padding-left: 20px;
    }

    .typho-1 {
        font-weight: 500;
        font-size: 12px;
        /* width: 100%; */
    }

    .headersecond .typho-1:last-child{
        justify-content: flex-end !important;
    }

    .hamburger{
        display: block;
    }

    .marquee-container, .headersecond{
        width: 100%;
    }

    .headersecond{
        width: 100%;
        gap: 15px;
        justify-content: space-around;
        padding: 0;
    }

    .marquee-container{
        display: none;
    }

   .navbar-nav {
    max-height: 100%;
    padding: 0;
   }

   .servicenavitem{
    position: relative;
    max-height: 100%;
   }
}

.productmegamenu{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 120%;
    padding: 5px 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-top: 1px solid black;
    cursor: pointer;
    border-radius: 10px;
    transition: visibility 0.5s, opacity 0.5s;
}

.serviceinnercolumninner{
    text-decoration: none;
    color: black;
}


@media screen and (max-width:600px) {
    .productmegamenu{
        width: 90%;
        top: 0;
        left: 0;
        display: none;
        visibility: visible;
        opacity: 1;
        position: relative;
    }

    .productmegamenu.open {
        display: block;
    }
}
